import Footer from "../components/footer";
import { useAuth } from "../contexts/AuthContext";
import {Redirect, useHistory} from "react-router-dom"
import {useRef, useState} from "react";

function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { currentUser } = useAuth();

  if(currentUser === null) {

    async function handleSubmit(e) {
      e.preventDefault()
      try {
        setError("")
        setLoading(true)
        await login(emailRef.current.value, passwordRef.current.value)
        history.push("/backOffice")
      } catch {
        setError("Wrong email/password combination")
      }

      setLoading(false)
    }

    return (
      <div>
        <div className="container context gradient-container" id="login-page">
          <div className="d-flex align-self-center justify-content-center w-100">
            <div className="col-md-5 mt-5" id="login-container">
              <div className="center">
                <h2 className="mt-5"><strong>Login</strong></h2>
                {error && <div className="alert alert-danger">{error}</div>}
              </div>
              <div className="center">
                <form onSubmit={handleSubmit}>
                  <div className="input-group col-lg-12">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-white px-4 border-md border-right-0">
                          <i className="fa fa-envelope text-muted"/>
                      </span>
                    </div>
                    <input id="email" type="email" name="email" placeholder="Email Address" ref={emailRef}
                           className="form-control bg-white border-left-0 border-md" required/>
                  </div>
                  <div className="input-group col-lg-12">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-white px-4 border-md border-right-0">
                          <i className="fa fa-lock text-muted"/>
                      </span>
                    </div>
                    <input id="password" type="password" name="password" placeholder="Password" ref={passwordRef}
                           autoComplete={"on"}
                           className="form-control bg-white border-left-0 border-md" required/>
                  </div>
                  <button disabled={loading} type="submit" className="btn btn-primary">Login</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    )
  }
  else {
    return (
      <Redirect to="/backOffice" />
    )
  }
}

export default Login;