import Footer from "../components/footer";
import {
  buildURL, compareArrays,
  descriptionToString, getSelectedSponsors, givePreSelectedSponsors,
  giveSponsorOptions, isOpen,
  stringToArray
} from "../utils/formationUtils";
import OfficeModal from "../components/officeModal";
import {useEffect, useState} from "react";
import {deleteFormation, updateFormation, uploadFilesToStorage, urlExists} from "../utils/firebaseUtils";
import {useHistory} from "react-router-dom";
import * as firebase from "../utils/firebaseUtils";
import Select from 'react-select';

function EditFormation(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [name, setName] = useState(props.data.name);
  const [place, setPlace] = useState(props.data.place);
  const [date, setDate] = useState(props.data.day);
  const [startTime, setStartTime] = useState(props.data.startTime);
  const [endTime, setEndTime] = useState(props.data.endTime);
  const [limitDate, setLimitDate] = useState(props.data.endInscDay);
  const [price, setPrice] = useState(props.data.price);
  const [spots, setSpots] = useState(props.data.numberOfSpots);
  const [smallDescription, setSmallDescription] = useState(props.data.smallDescription);
  const [bigDescription, setBigDescription] = useState(props.data.largeDescription);
  const [active, setActive] = useState(props.data.active);
  const [error, setError] = useState('');
  const [program, setProgram] = useState();
  const [image, setImage] = useState();
  const history = useHistory();
  const options = giveSponsorOptions(props.sponsors);
  const preSelectedOptions = givePreSelectedSponsors(options, props.data.sponsors);
  const [selectedSponsors, setSelectedSponsors] = useState(preSelectedOptions);

  useEffect(() => {
    const getProgram = async () => {
      firebase.storage.ref(props.data.program).getDownloadURL().then(url => {
        setProgram(url);
      })
    };

    const getImage = async () => {
      firebase.storage.ref(props.data.img).getDownloadURL().then(url => {
        setImage(url);
      })
    };

    getProgram();
    getImage();
  }, []);

  const handleChange = (selectedOptions) => {
    setSelectedSponsors(selectedOptions);
  }

  const handleDelete = (e) => {
    e.preventDefault();
    setModalInfo({
      title: 'Eliminar Formação',
      text: 'Tens a certeza que queres eliminar a formação?',
      actionFunc: remove,
    })
    setModalOpen(true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(name === props.data.name
      && place === props.data.place
      && date === props.data.day
      && startTime === props.data.startTime
      && endTime === props.data.endTime
      && limitDate === props.data.endInscDay
      && smallDescription === props.data.smallDescription
      && bigDescription === props.data.largeDescription
      && spots ===  props.data.numberOfSpots
      && price === props.data.price
      && active === props.data.active
      && (typeof program) === 'string'
      && (typeof image) === 'string'
      && compareArrays(props.data.sponsors, getSelectedSponsors(selectedSponsors))){
      setError('Não alteraste nada!')
    }
    else {
      setError('');
      const forms = document.getElementsByClassName('needs-validation');
      Array.prototype.filter.call(forms, async function(form) {
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        }
        else if(!document.getElementById('smallDesc').value.match('^(?!.*<[^>]+>).*')) {
          document.getElementById('smallDesc').style.borderColor = "#dc3545";
          document.getElementById('smallDesc-feedback').style.display = "inline";
          e.preventDefault();
          e.stopPropagation();
        }
        else if(!document.getElementById('bigDesc').value.match('^(?!.*<[^>]+>).*')) {
          document.getElementById('bigDesc').style.borderColor = "#dc3545";
          document.getElementById('bigDesc-feedback').style.display = "inline";
          e.preventDefault();
          e.stopPropagation();
        }
        else if ((typeof image) !== 'string' && image.type.split('/')[0] !== "image"){
          document.getElementById('image').style.borderColor = "#dc3545";
          document.getElementById('image-feedback').style.display = "inline";
          e.preventDefault();
          e.stopPropagation();
        }
        else if ((typeof program) !== 'string' && program.type !== "application/pdf"){
          document.getElementById('program').style.borderColor = "#dc3545";
          document.getElementById('program-feedback').style.display = "inline";
          e.preventDefault();
          e.stopPropagation();
        }
        else if(getSelectedSponsors(selectedSponsors).length === 0) {
          document.getElementById('sponsor-feedback').style.display = "inline";
          e.preventDefault();
          e.stopPropagation();
        }
        else {
          setModalInfo({
            title: 'Editar Formação',
            text: 'Tens a certeza que queres guardar as alterações?',
            actionFunc: edit,
          })
          setModalOpen(true);
        }
        form.classList.add('was-validated');
      }, false);
    }
  }

  const edit = async () => {
    const formation = {
      id: props.data.id,
      name: name,
      place: place,
      day: date,
      endInscDay: limitDate,
      active: active,
      open: isOpen(limitDate, active),
      endTime: endTime,
      startTime: startTime,
      price: price === props.data.price ? price : parseFloat(price),
      numberOfSpots: spots === props.data.numberOfSpots ? spots : parseInt(spots),
      url: buildURL(date, name),
      smallDescription: smallDescription === props.data.smallDescription ? smallDescription : stringToArray(smallDescription),
      largeDescription: bigDescription === props.data.largeDescription ? bigDescription : stringToArray(bigDescription),
      program: props.data.program,
      img: props.data.img,
    };
    formation.sponsors = getSelectedSponsors(selectedSponsors);
    const exists = await urlExists(formation.url);
    if(exists !== 0) {
      formation.url = formation.url + "_" + exists;
    }
    if((typeof program) !== "string") {
      uploadFilesToStorage(props.data.id, program, "program", true);
    }
    if((typeof image) !== "string") {
      uploadFilesToStorage(props.data.id, image, "image", true);
    }
    await updateFormation(formation);
    history.push('/backOffice')
  }

  const remove = async () => {
    await deleteFormation(props.data.id, props.data.url);
    history.push('/backOffice')
  };

  return (
    <div>
      <div className="container context gradient-container" id="editFormation-page">
        <div className="row align-items-center justify-content-center">
          <div className="col-8 formacao-intro">
            <div className="row align-items-center justify-content-center mt-5">
              <form className="needs-validation" noValidate={true} id="editFormationForm" onSubmit={(e) => handleSubmit(e)}>
                <div className="form-group">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-7 label-input">
                      <h2>Editar Formação</h2>
                    </div>
                    <div className="col-md-5 label-input check-div">
                      <input className="form-check-input" type="checkbox" value="" defaultChecked={props.data.active}
                             id="activeFormation" onChange={() => setActive(!active)}/>
                        <label htmlFor="activeFormation">
                          Formação Ativa
                        </label>
                    </div>
                    {error && <div className="alert alert-warning col-md-3">{error}</div>}
                  </div>
                </div>
                <div className="form-group">
                  <div className="row d-flex justify-content-center">
                    <div className="col label-input">
                      <label htmlFor="name">Nome</label>
                      <input type="text"
                             className="form-control" id="name" placeholder="Nome da formação" defaultValue={props.data.name}
                             onChange={e => setName(e.target.value)} required />
                      <div className="invalid-feedback">
                        Nome é obrigatório, e só pode conter letras e/ou espaços!
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row d-flex justify-content-center">
                    <div className="col label-input">
                      <label htmlFor="place">Local</label>
                      <input type="text" pattern="^(?!.*<[^>]+>).*"
                             className="form-control" id="place" placeholder="Local da formação" defaultValue={props.data.place}
                             onChange={e => setPlace(e.target.value)} required />
                      <div className="invalid-feedback">
                        Local é obrigatório!
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-4 mt-2 label-input">
                      <label htmlFor="date">Data</label>
                      <input type="date"
                             className="form-control" id="date" defaultValue={props.data.day}
                             onChange={e => setDate(e.target.value)} required/>
                      <div className="invalid-feedback">
                        Data é obrigatória!
                      </div>
                    </div>
                    <div className="col-md-4 mt-2 label-input">
                      <label htmlFor="startTime">Horário de início</label>
                      <input type="time"
                             className="form-control" id="startTime" defaultValue={props.data.startTime}
                             onChange={e => setStartTime(e.target.value)} required/>
                      <div className="invalid-feedback">
                        Hora de início é obrigatória!
                      </div>
                    </div>
                    <div className="col-md-4 mt-2 label-input">
                      <label htmlFor="endTime">Horário de término</label>
                      <input type="time"
                             className="form-control" id="endTime" defaultValue={props.data.endTime} min={startTime}
                             onChange={e => setEndTime(e.target.value)} required/>
                      <div className="invalid-feedback">
                        Hora de término é obrigatória e tem que ser depois da hora de início!
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-4 mt-2 label-input">
                      <label htmlFor="limitDate">Inscrições até</label>
                      <input type="date"
                             className="form-control" id="limitDate" defaultValue={props.data.endInscDay} max={date}
                             onChange={e => setLimitDate(e.target.value)} required/>
                      <div className="invalid-feedback">
                        Data limite de inscrições é obrigatório e tem que ser antes da data da formação!
                      </div>
                    </div>
                    <div className="col-md-4 mt-2 label-input">
                      <label htmlFor="price">Preço</label>
                      <input type="number"
                             className="form-control" id="price" placeholder="Preço" defaultValue={props.data.price}
                             onChange={e => setPrice(e.target.value)} required/>
                      <div className="invalid-feedback">
                        Preço é obrigatório!
                      </div>
                    </div>
                    <div className="col-md-4 mt-2 label-input">
                      <label htmlFor="spots">Número de vagas</label>
                      <input type="number" min='1' pattern="^[0-9]*$"
                             className="form-control" id="spots" placeholder="Número de vagas" defaultValue={props.data.numberOfSpots}
                             onChange={e => setSpots(e.target.value)} required/>
                      <div className="invalid-feedback">
                        Número de vagas é obrigatório!
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row d-flex justify-content-center">
                    <div className="col label-input">
                      <label htmlFor="smallDesc">Pequena Descrição</label>
                      <textarea className="form-control" id="smallDesc" rows="6"
                                defaultValue={descriptionToString(props.data.smallDescription)}
                                onChange={e => setSmallDescription(e.target.value)} required/>
                      <div className="invalid-feedback" id="smallDesc-feedback">
                        Pequena Descrição é obrigatória e não pode contar tags de html!
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row d-flex justify-content-center">
                    <div className="col label-input">
                      <label htmlFor="bigDesc">Grande Descrição</label>
                      <textarea className="form-control" id="bigDesc" rows="10" defaultValue={descriptionToString(props.data.largeDescription)}
                                onChange={e => setBigDescription(e.target.value)} required/>
                      <div className="invalid-feedback" id="bigDesc-feedback">
                        Grande Descrição é obrigatória e não pode contar tags de html!
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="form-group">
                  <div className="row d-flex justify-content-center">
                    <div className="col label-input">
                      <label htmlFor="image">Imagem: </label>
                      <a className={"programa highlight-link highlight-link-" + props.color} href={image} target="_blank"
                         rel="noopener noreferrer"><strong> imagem</strong></a>
                    </div>
                    <div className="col label-input">
                      <label htmlFor="program">Programa: </label>
                      <a className={"programa highlight-link highlight-link-" + props.color} href={program} target="_blank"
                         rel="noopener noreferrer"><strong> programa</strong></a>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row d-flex justify-content-center">
                    <div className="col label-input">
                      <label htmlFor="image">Escolher outra imagem: </label>
                      <input type="file"
                             className="form-control" id="image"
                             onChange={e => setImage(e.target.files[0])}
                      />
                      <div className="invalid-feedback" id="image-feedback">
                        Imagem é obrigatória!
                      </div>
                    </div>
                    <div className="col label-input">
                      <label htmlFor="program">Escolher outro programa: </label>
                      <input type="file"
                             className="form-control" id="program"
                             onChange={e => setProgram(e.target.files[0])}
                      />
                      <div className="invalid-feedback" id="program-feedback">
                        Programa é obrigatório e tem que ser um pdf!
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="form-group">
                  <label htmlFor="showSponsors">Organizadores </label>
                  <Select
                    defaultValue={preSelectedOptions}
                    isMulti
                    name="colors"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback" id="sponsor-feedback">
                    Tem que ser selecionado, pelo menos, 1 organizador!
                  </div>
                </div>
                <div className="form-group">
                  <div className="row d-flex justify-content-center actions-div">
                    <div className="col-md-4 col-sm-4 mt-2 label-input justify-content-center">
                      <button type="submit" className="btn modal-btn" >
                        <strong>Guardar Alterações</strong>
                      </button>
                    </div>
                    <div className="col-md-4 col-sm-4 mt-2 label-input justify-content-center">
                      <button type="button" className="btn modal-btn" onClick={(e) => handleDelete(e)}>
                        <strong>Eliminar Formação</strong>
                      </button>
                      {modalOpen ? <OfficeModal setModalOpen={setModalOpen}
                                                title={modalInfo.title}
                                                text={modalInfo.text}
                                                actionFunc={modalInfo.actionFunc}
                      /> : null}
                    </div>
                    <div className="col-md-4 col-sm-4 mt-2 label-input justify-content-center">
                      <button type="button" className="btn modal-btn" onClick={() => history.push('/backOffice')}>
                        <strong>Cancelar</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )

}

export default EditFormation;