function Footer() {
    return (
        <footer id="footer" className="footer d-flex">
          <div className="container">
            <div className="float-left copyright">
              &copy; 2021
              <a href="https://www.junifeup.pt" target="_blank" rel="noreferrer">JuniFEUP</a>
            </div>
            <div className="social-area float-right">
              <a href="https://www.linkedin.com/company/junifeup" target="_blank" className="btn btn-social btn-simple" rel="noreferrer"><i
  className="fa fa-linkedin fa-fw"/>
                <span className="network-name"/></a>
              <a href="https://www.facebook.com/junifeup/" target="_blank" className="btn btn-social btn-simple" rel="noreferrer"><i
  className="fa fa-facebook fa-fw"/>
                <span className="network-name"/></a>
              <a href="https://www.instagram.com/junifeup/" target="_blank" className="btn btn-social btn-simple" rel="noreferrer"><i
  className="fa fa-instagram fa-fw"/>
                <span className="network-name"/></a>
            </div>
          </div>
      </footer>
    );
  }
  
  export default Footer;