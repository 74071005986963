function ErrorModal(props) {
    return (
      <section className="popup popup-with-backcolor" id="rejection" >
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className="modal-content" style={{textAlign: 'center'}}>
            <button type="button" className="close" data-dismiss="modal" onClick={() => props.setErrorMessage(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h3 className="text-center title-padding title-mobile">
              <strong>Inscrição Rejeitada</strong>
            </h3>
            <p style={{fontSize: '16px'}}>
              Ocorreu um erro, por favor tente mais tarde</p>
          </div>
        </div>
      </section>
    );
}
  
export default ErrorModal;
