import Footer from "../components/footer";
import {giveStatus} from "../utils/formationUtils";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {db} from "../utils/firebaseUtils";

function BackOffice() {
  const history = useHistory();
  const [formations, setFormations] = useState([]);

  useEffect(() => {
    const getFormations = async () => {
      const formationRef = db.collection('formations');
      const snapshot = await formationRef.orderBy("day", "desc").get();
      setFormations(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }

    getFormations();
  }, []);

  return (
    <>
      <div className="container context gradient-container" id="backoffice-page">
        <div className="container justify-content-center g-0">
          <h2 className="mt-3 mb-5">Formações</h2>
          <div className="row justify-content-center mb-5">
            <table className="table">
              <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Data</th>
                <th scope="col">Status</th>
                <th scope="col">Participantes</th>
              </tr>
              </thead>
              <tbody>
              {
                formations.map((formation) => {
                  return (
                    <tr key={formation.id} onClick={() => history.push("/editarFormacao" + formation.url)} id="formationRow">
                      <th scope="row">{formation.name}</th>
                      <td>{formation.day}</td>
                      <td>{giveStatus(formation.active)}</td>
                      <td>
                        <button type="submit" className="btn modal-btn" onClick={(e) => {e.stopPropagation(); history.push("/participantes" + formation.url)}}>
                          <strong>Mostrar</strong>
                        </button>
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
          <div className="row col-10 add-row">
            <button type="submit" className="btn modal-btn" onClick={() => history.push('/adicionarFormacao')}>
              <strong>Adicionar formação</strong>
            </button>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default BackOffice;