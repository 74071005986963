import Footer from '../components/footer';
import CardButton from '../components/cardWithButton';

import {simplifiedDate} from "../utils/formationUtils";
import {useEffect, useState} from "react";
import * as firebase from "../utils/firebaseUtils";
import Sponsor from "../components/sponsor";
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../utils/firebaseUtils";

function FormationPage(props) {
  const sponsorsCollectionRef = collection(firebase.db, "sponsors");
  const [sponsors, setSponsors] = useState([]);
  const [url, setUrl] = useState({});
  const [image, setImage] = useState({});
  const [open, setOpen] = useState(props.data.open);
  let formationSponsors = [];

  useEffect(() => {
    const getSponsors = async () => {
      const d = await getDocs(sponsorsCollectionRef);
      setSponsors(d.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }

    const getProgram = async () => {
      firebase.storage.ref(props.data.program).getDownloadURL().then(url => {
        setUrl(url);
      })
    };

    const getImage = async () => {
      firebase.storage.ref(props.data.img).getDownloadURL().then(url => {
        setImage(url);
      })
    };

    const updateOpen = async () => {
      if(props.data.open && new Date() > new Date(props.data.endInscDay + ' 23:59:59')) {
        await updateDoc(doc(db, 'formations/', props.data.id), {
          open: false
        });
        setOpen(false);
      }
    }

    getProgram();
    getImage();
    getSponsors();
    updateOpen();
  }, []);

  function merge() {
    let m;
    props.data.sponsors.map((sponsorID) => {
      sponsors.map((sponsor) => {
        if(sponsor.id === sponsorID) {
          m = {
            img: sponsor.img,
            description: sponsor.description
          }
          formationSponsors.push(m);
        }
      })
    })
  }
  merge();

  return (
    <>
      <div className="container context gradient-container" id="formation-page">
        <div className="row align-items-center justify-content-center">
          <div className="col-12">
            <div className="center formacao-intro">
              <div className="row align-items-center justify-content-center mt-5">
                <div className="col-md-6 intro">
                  <h4>Formação Certificada</h4>
                  <h2>{props.data.name}</h2>
                  {
                    props.data.smallDescription.map((description, index) => {
                      return (
                        <p key={index}>
                          {description}
                        </p>
                      )
                    })
                  }
                </div>
                <div className="col-md-4 offset-md-1" id="pm-place-time">
                  <h5><strong>LOCAL</strong></h5>
                  <p>A formação será realizada em {props.data.place} </p>
                  {props.data.place === 'formato online' ?
                    <p>O link será, antecipadamente, enviado por email para todos os inscritos.</p> : null}
                  <h5><strong>HORÁRIO</strong></h5>
                  <p>{simplifiedDate(props.data.day)}</p>
                  <p>{props.data.startTime}-{props.data.endTime}</p>
                </div>
              </div>
            </div>
            <h4 className="header-text">FORMAÇÃO CERTIFICADA EM {props.data.name} FORNECIDA POR:</h4>
          </div>
        </div>
        <div className="row align-items-center justify-content-center" id="pm-organizadores">
          {
            formationSponsors.map((sponsor) => {
              return (
                <div className="col-md-4 col-sm-6 organizadores" key={sponsor.id}>
                  <Sponsor data={sponsor}/>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="container" id="descricao">
        <div className="row justify-content-center" id="descricao-row">
          <div className="col-md-9">
            <div className="description">
              {
                props.data.largeDescription.map((desc, index) => {
                  return (
                    <p key={index}>
                      {desc}
                    </p>
                  )
                })
              }
              <p>
                Poderás consultar aqui o
                <a className={"programa highlight-link highlight-link-" + props.color} href={url} target="_blank"
                   rel="noopener noreferrer"><strong> programa</strong></a>.
              </p>
            </div>
          </div>
          <div className="col-md-3 d-lg-block" id="card-pm-page">
            <CardButton id={props.data.id}
                        name={props.data.name}
                        maxSpots={props.data.numberOfSpots}
                        img={image}
                        title={props.data.name}
                        date={props.data.day}
                        startTime={props.data.startTime}
                        endTime={props.data.endTime}
                        price={props.data.price}
                        endInsc={props.data.endInscDay}
                        open={open}
                        color={props.color}/>
          </div>
        </div>
        <div className="details description">
          <ul>
            <li><strong>Prazo limite de inscrição:</strong> {simplifiedDate(props.data.endInscDay)}!</li>
            <li><strong>Preço:</strong> {props.data.price}€ <p>Assim que recebermos o teu contacto, dar-te-emos mais
              detalhes sobre a finalização da inscrição e como deves proceder para efetuar o pagamento.</p></li>
            <li>
              <strong>Atenção - as vagas são limitadas!</strong>
              <p>
                Em caso de dúvida, não hesites em contactar-nos através de <ins>
                <a className="mail" href="mailto:formacoes@junifeup.pt">formacoes@junifeup.pt.</a></ins>
              </p>
              <p>
                Termos & Condições: A JuniFEUP não poderá devolver o dinheiro em caso de desistência, a menos que esta
                seja feita com 1 semana de antecedência e com um motivo devidamente justificado. As vagas são
                preenchidas por ordem de pagamento das inscrições.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default FormationPage;
