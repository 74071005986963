import {useState} from 'react';
import * as firebase from '../utils/firebaseUtils';
import emailjs from 'emailjs-com';
import {addHours, getDayOfWeek, getPreviousDay} from "../utils/formationUtils";
import {payment} from "../utils/easypayUtils";

function Modal(props) {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [studentNumber, setStudentNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [idCardNumber, setIdCardNumber] = useState('');
  const [idCardExpirationDate, setIdCardExpirationDate] = useState('');
  const [nif, setNIF] = useState('');
  const [institution, setInstitution] = useState('');
  const [course, setCourse] = useState('');
  const [year, setYear] = useState('');
  const [referral, setReferral] = useState('');
  const [newsletter, setNewslettter] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState('');

  function sendNotificationEmail(user) {
    let templateParams = {
      to_email: user.email,
      name: user.fullName,
      formation: props.formationName,
      date: getDayOfWeek(props.date),
      begin_hour: props.startTime,
      end_hour: props.endTime,
      value: props.price,
      valid_days: "3"
    };

    if (user.paymentMethod === 'mb') {
      templateParams["reference"] = user.reference
      templateParams["entity"] = user.entity
      emailjs.init("user_ptswkl7Nhzbj2Dzpxb5Tu");
      emailjs.send("service_v1dhauf", "template_xuqoi31", templateParams);
    } else if (user.paymentMethod === 'mbw') {
      emailjs.init("user_ptswkl7Nhzbj2Dzpxb5Tu");
      emailjs.send("service_v1dhauf", "template_n8nh8lz", templateParams);
    }

  }

  const changePage = (error, user) => {
    if (!error && user) {
      firebase.registerUser(user, props.formation);
      props.setPaymentData({
        paymentMethod: user.paymentMethod,
        entity: user.entity,
        reference: user.reference,
        value: props.price
      })
      sendNotificationEmail(user);
      props.setModalOpen(false);
      props.setConfirmationMessage(true);
    } else {
      console.log(error);
      props.setModalOpen(false);
      props.setErrorMessage(true);
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    const forms = document.getElementsByClassName('needs-validation');
    Array.prototype.filter.call(forms, async function(form) {
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      else {
        const expireDate = addHours(72) < getPreviousDay(props.date) ? addHours(72) : getPreviousDay(props.date)

        const registration = {
          email,
          fullName,
          studentNumber,
          phoneNumber,
          nif,
          birthDate,
          idCardNumber,
          idCardExpirationDate,
          institution,
          course,
          year,
          referral,
          newsletter,
          paymentMethod,
          registrationExpiration: expireDate,
          sentExpirationEmail: false,
          paid: false
        };

        if (await firebase.getFilledSpace(props.formation) < props.maxSpots) {
          await payment(registration, props.formationName, props.price, changePage);
        } else {
          props.setModalOpen(false);
          props.setRejectionMessage(true);
        }
      }
      form.classList.add('was-validated');
    }, false);
  }
  return (
    <div className="popup popup-with-backcolor">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close" data-dismiss="modal" onClick={() => props.setModalOpen(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
            <section id="modal-registration">
              <div className="container">
                <h3 className="text-center title-padding title-mobile">
                  <strong>Inscrição</strong>
                </h3>
                <p style={{fontSize: '16px'}}>
                  Formação certificada pela APGEI (exclusivo para alunos do
                  Ensino Superior).
                </p>
                <p style={{fontSize: '16px'}}>
                  O custo da formação é de {props.price}€. Após submeteres a inscrição,
                  iremos enviar-te um email com os detalhes do pagamento, que
                  terá de ser feito até 72 horas após a submissão, de forma
                  a podermos validá-la.
                </p>
                <p style={{
                  fontSize: '16px',
                  paddingBottom: '10px'
                }}>
                  Caso tenhas alguma questão, contacta-nos através de: <ins>formacoes@junifeup.pt</ins>
                </p>
                <small className="text-muted">
                  *Caso a inscrição seja feita no dia anterior ou no próprio dia limite à inscrição, o pagamento tem que
                  ser feito no máximo até ao dia anterior à Formação.
                </small>
                <br/>
                <small className="text-muted">
                  *Tem em atenção que o email com os detalhes de pagamento pode ir para a pasta spam.
                </small>
                <form className="needs-validation" onSubmit={(e) => handleSubmit(e)} noValidate={true} id="registerForm">
                  <div className="form-group">
                    <div className="row d-flex justify-content-center">
                      <div className="col label-input">
                        <label htmlFor="fullName">Nome Completo</label>
                        <input type="text" value={fullName} onChange={e => setFullName(e.target.value)}
                               className="form-control" id="fullName" placeholder="Introduza o seu nome completo"
                               required/>
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row d-flex justify-content-center">
                      <div className="col label-input">
                        <label htmlFor="emailReq">Endereço de E-mail</label>
                        <input type="email" value={email} onChange={e => setEmail(e.target.value)}
                               className="form-control" id="emailReq" placeholder="Introduza o seu e-mail"
                               required/>
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="studentNumber">Número de Estudante</label>
                        <input type="number" value={studentNumber} onChange={e => setStudentNumber(e.target.value)}
                               className="form-control" id="studentNumber" placeholder="Número de estudante" pattern="^[0-9]+$"
                               required/>
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="phoneNumber">Telemóvel</label>
                        <input type="number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}
                               className="form-control" id="phoneNumber" placeholder="Telemóvel" pattern="^[0-9]+$" required/>
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="birthDate">Data de Nascimento</label>
                        <input type="date" value={birthDate} onChange={e => setBirthDate(e.target.value)}
                               className="form-control" id="birthDate" placeholder="Data de Nascimento"
                               required/>
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="IDCard">Cartão de Cidadão</label>
                        <input type="text" value={idCardNumber} onChange={e => setIdCardNumber(e.target.value)}
                               className="form-control" id="IDCard" placeholder="Nº de Cartão de Cidadão" pattern="^[0-9]+$"
                               required/>
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="IDValDate">Data de Validade</label>
                        <input type="date" value={idCardExpirationDate}
                               onChange={e => setIdCardExpirationDate(e.target.value)} className="form-control"
                               id="IDValDate" placeholder="Validade Cartão Cidadão"
                               required/>
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="NIF">NIF</label>
                        <input type="number" value={nif} onChange={e => setNIF(e.target.value)} className="form-control" pattern="^[0-9]+$"
                               id="NIF" placeholder="Introduza o seu NIF" required/>
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="school">Instituição de Ensino</label>
                        <input type="text" value={institution} onChange={e => setInstitution(e.target.value)}
                               className="form-control" id="school" placeholder="Instituição de Ensino"
                               required/>
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="course">Curso</label>
                        <input type="text" value={course} onChange={e => setCourse(e.target.value)}
                               className="form-control" id="course" placeholder="Curso" required/>
                        <div className="invalid-feedback">Obrigatório</div>
                      </div>
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="grade">Ano Curricular</label>
                        <input type="number" value={year} onChange={e => setYear(e.target.value)} min="1" pattern="^[0-9]+$"
                               className="form-control" id="grade" placeholder="Ano Curricular"
                               required/>
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group paymentRadio" onChange={(e) => setPaymentMethod(e.target.value)}>
                    <div className="custom-control custom-radio payment-div">
                      <div className="row d-flex">
                        <div className="col-md-4 label-input" style={{paddingLeft: '0'}}>
                          <span>Método de pagamento:</span>
                        </div>
                        <div className="col-md-4 label-input">
                          <input type="radio" name="paymentRadio" className="custom-control-input" id="radio-payment1"
                                 value="mb" required/>
                          <label className="custom-control-label" htmlFor="radio-payment1">Referência Multibanco</label>
                          <div className="invalid-feedback">Obrigatório!</div>
                        </div>
                        <div className="col-md-3 label-input">
                          <input type="radio" name="paymentRadio" className="custom-control-input" id="radio-payment2"
                                 value="mbw"/>
                          <label className="custom-control-label" htmlFor="radio-payment2">MBWAY</label>
                          <div className="invalid-feedback">Obrigatório!</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p style={{
                    fontSize: '16px',
                    marginBottom: '5px'
                  }}>
                    Como tomaste conhecimento da formação?
                  </p>
                  <div className="form-group optionRadio" onChange={(e) => setReferral(e.target.value)}>
                    <div className="custom-control custom-radio">
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-4 label-input">
                          <input type="radio" name="optionRadio" className="custom-control-input" id="radio1"
                                 value="Facebook da JuniFEUP" required/>
                          <label className="custom-control-label" htmlFor="radio1">Facebook da JuniFEUP</label>
                        </div>
                        <div className="col-md-4 label-input">
                          <input type="radio" name="optionRadio" className="custom-control-input" id="radio2"
                                 value="Cartaz afixado"/>
                          <label className="custom-control-label" htmlFor="radio2">Cartaz afixado</label>
                        </div>
                        <div className="col-md-4 label-input">
                          <input type="radio" name="optionRadio" className="custom-control-input" id="radio3"
                                 value="Entidades parceiras"/>
                          <label className="custom-control-label" htmlFor="radio3">Entidades parceiras</label>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-4 label-input">
                          <input type="radio" name="optionRadio" className="custom-control-input" id="radio4"
                                 value="Instagram da JuniFEUP" required/>
                          <label className="custom-control-label" htmlFor="radio4">Instagram da JuniFEUP</label>
                        </div>
                        <div className="col-md-4 label-input">
                          <input type="radio" name="optionRadio" className="custom-control-input" id="radio5"
                                 value="Panfleto"/>
                          <label className="custom-control-label" htmlFor="radio5">Panfleto</label>
                        </div>
                        <div className="col-md-4 label-input">
                          <input type="radio" name="optionRadio" className="custom-control-input" id="radio6"
                                 value="Email de Divulgação"/>
                          <label className="custom-control-label" htmlFor="radio6">Email de Divulgação</label>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-4 label-input">
                          <input type="radio" name="optionRadio" className="custom-control-input" id="radio7"
                                 value="LinkedIn da JuniFEUP" required/>
                          <label className="custom-control-label" htmlFor="radio7">LinkedIn da JuniFEUP</label>
                        </div>
                        <div className="col-md-4 label-input">
                          <input type="radio" name="optionRadio" className="custom-control-input" id="radio8"
                                 value="Amigos/Colegas/Familiares"/>
                          <label className="custom-control-label" htmlFor="radio8">Amigos/Colegas/Familiares</label>
                        </div>
                        <div className="col-md-4 label-input">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group optionCheck align-items-center justify-content-center">
                    <div className="custom-control custom-checkbox">
                      <div className="row justify-content-center">
                        <input type="checkbox" name="optionCheck" id="juniEmails" className="custom-control-input"
                               defaultChecked={newsletter} onChange={() => setNewslettter(!newsletter)}/>
                        <label className="custom-control-label" htmlFor="juniEmails">Aceito receber divulgações acerca
                          de
                          oportunidades futuras da JuniFEUP</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    <button type="submit" className="btn modal-btn">
                      <strong>Inscrever</strong>
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Modal;
