export function simplifiedDate(date) {
  const array = date.split("-")
  let month = array[1]
  switch (month) {
    case '01' || '1' :
      month = 'janeiro'
      break
    case '02' || '2':
      month = 'fevereiro'
      break
    case '03' || '3':
      month = 'março'
      break
    case '04' || '4':
      month = 'abril'
      break
    case '05' || '5':
      month = 'maio'
      break
    case '06' || '6':
      month = 'junho'
      break
    case '07' || '7':
      month = 'julho'
      break
    case '08' || '8':
      month = 'agosto'
      break
    case '09' || '9':
      month = 'setembro'
      break
    case '10':
      month = 'outubro'
      break
    case '11':
      month = 'novembro'
      break
    case '12':
      month = 'dezembro'
      break
  }

  return array[2] + ' de ' + month
}

export function getDayOfWeek(date) {
  const d = new Date(date);
  switch (d.getDay()) {
    case 0:
      return "Domingo, dia " + simplifiedDate(date)
    case 1:
      return "Segunda-feira, dia " + simplifiedDate(date)
    case 2:
      return "Terça-feira, dia " + simplifiedDate(date)
    case 3:
      return "Quarta-feira, dia " + simplifiedDate(date)
    case 4:
      return "Quinta-feira, dia " + simplifiedDate(date)
    case 5:
      return "Sexta-feira, dia " + simplifiedDate(date)
    case 6:
      return "Sábado, dia " + simplifiedDate(date)
  }
}

export function addHours(hours) {
  const d = new Date();
  return new Date(new Date(d).setHours(d.getHours() + hours)).getTime();
}

export function getPreviousDay(date) {
  const realDate = new Date(date + ' 23:59:59');
  return new Date(realDate.setDate(realDate.getDate() - 1)).getTime();
}

export function giveStatus(bool) {
  if(bool) {
    return "Active"
  }
  else {
    return "Not Active"
  }
}

export function descriptionToString(array) {
  let finalString='';
  array.map((phrase) => {
    if(phrase === array[array.length-1])
      finalString = finalString + phrase
    else
      finalString = finalString + phrase + '\n'
  })
  return finalString;
}

export function buildURL(date, name) {
  const realDate = new Date(date);
  const months = ["Jan", "Feb", "Mar", "Abr", "Maio", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
  return name.replace(/\s/g, "") + realDate.getFullYear() + months[realDate.getMonth()]
}

export function stringToArray(string) {
  return string.split(/\n/g)
}

export function compareArrays(a, b) {
  if(a.length !== b.length)
    return false;
  else
  {
    for(let i = 0; i < a.length; i++)
      if(a[i] !== b[i])
        return false;
    return true;
  }
}

export function giveSponsorOptions(sponsors) {
  let options = [];
  sponsors.map((sponsor) => {
    options.push({
      label: sponsor.id,
      value: sponsor.id
    })
  })
  return options;
}

export function givePreSelectedSponsors(options, sponsors) {
  let selected = [];
  sponsors.map((sponsor) => {
    options.map((opt) => {
      if(sponsor === opt.value) {
        selected.push(opt);
      }
    })
  })
  return selected;
}

export function getSelectedSponsors(selectedOptions) {
  let sponsors = [];
  selectedOptions.map((opt) => {
    sponsors.push(opt.value);
  })

  return sponsors;
}

export function isAllFalse(array) {
  let flag = true;
  array.forEach((value) => {
    if(value) {
      flag = false;
    }
  })

  return flag;
}

export function isOpen(limitDate, active) {
  return (new Date() < new Date(limitDate + ' 23:59:59')) && active;
}