function RejectionModal(props) {
    return (
      <section className="popup popup-with-backcolor" id="rejection" >
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className="modal-content" style={{textAlign: 'center'}}>
            <button type="button" className="close" data-dismiss="modal" onClick={() => props.setRejectionMessage(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h3 className="text-center title-padding title-mobile">
              <strong>Inscrição Rejeitada</strong>
            </h3>
            <p style={{fontSize: '16px'}}>
              As vagas para a formação, na data escolhida, encontram-se esgotadas.</p>
            <p style={{fontSize: '16px'}}>Por favor verifica as vagas em outra data.</p>
          </div>
        </div>
      </section>
    );
}
  
export default RejectionModal;
