import * as firebase from "../utils/firebaseUtils";
import {useEffect, useState} from "react";

function Sponsor(props) {
  const [image, setImage] = useState([]);

  useEffect(() => {
    const getImage = async () => {
      firebase.storage.ref(props.data.img).getDownloadURL().then(url => {
        setImage(url);
      })
    };

    getImage();
  }, []);

  return (
      <>
        <div className="organizadores-logo">
          <img className="company_img img-fluid" alt="" src={image} />
        </div>
        <div className="description">
          <p>
            {props.data.description}
          </p>
        </div>
      </>
  )
}

export default Sponsor;