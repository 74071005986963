import Footer from '../components/footer';
import Card from '../components/card';
import {useEffect, useState} from "react";
import {db} from "../utils/firebaseUtils";

function Main() {
  const [formations, setFormations] = useState([]);
  let i = 0;
  const colors = ['purple', 'pink', 'blue'];

  useEffect(() => {
    const getActiveFormations = async () => {
      const formationRef = db.collection('formations');
      const snapshot = await formationRef.where('active', '==', true).get();
      setFormations(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }

    getActiveFormations();
  }, []);

  return (
    <div>
      <div className="container gradient-container" id="main-page">
          <div className="d-flex align-self-center w-100">
            <div className="col-md-12 my-3" id="landing-page-title">
              <div className="center">
                <h2 className="mt-3"><strong>Formações Certificadas</strong></h2>
              </div>
              <div className="center">
                <div className="description">
                  <h5>
                    Não percas esta oportunidade de investires no teu futuro.
                  </h5>
                  <h5>Inscreve-te já!</h5>
                </div>
                <div className="buttons row">
                  <div className="row align-items-center justify-content-center" id="landing-page-cards">
                    {
                      formations.map((formation) => {
                        i++
                        if(i === 3) i=0
                        const size = formations.length % 3 === 1? "col-md-12":"col-md-4"
                        return (
                          <div className={size} key={formation.id}>
                              <Card img={formation.img}
                                    name={formation.name}
                                    date={formation.day}
                                    startTime={formation.startTime}
                                    endTime={formation.endTime}
                                    price={formation.price}
                                    color={colors[i]}
                                    link={formation.url}/>
                            </div> 
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    <Footer />
    </div>
        
  );
}

export default Main;