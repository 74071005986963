import {useHistory} from "react-router-dom";
import Footer from "../components/footer";
import {useEffect, useState} from "react";
import {collection, getDocs} from "firebase/firestore";
import * as firebase from "../utils/firebaseUtils";

function Sponsors() {
  const history = useHistory();
  const [sponsors, setSponsors] = useState([]);

  useEffect(() => {
    const getSponsors = async () => {
      const sponsorsCollectionRef = collection(firebase.db, "sponsors");
      const d = await getDocs(sponsorsCollectionRef);
      setSponsors(d.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }
    getSponsors();
  }, []);

  return (
    <>
      <div className="container context gradient-container" id="sponsors-page">
        <div className="container justify-content-center g-0">
          <h2 className="mt-3 mb-5">Organizadores</h2>
          <div className="row justify-content-center mb-5">
            <table className="table">
              <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Descrição</th>
              </tr>
              </thead>
              <tbody>
              {
                sponsors.map((sponsor) => {
                  return (
                    <tr key={sponsor.id} onClick={() => history.push("/editarOrganizador" + sponsor.id)} id="sponsorRow">
                      <td scope="row">{sponsor.id}</td>
                      <td>{sponsor.description}</td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
          <div className="row col-10 add-row">
            <button type="submit" className="btn modal-btn" onClick={() => history.push('/adicionarOrganizador')}>
              <strong>Adicionar organizador</strong>
            </button>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Sponsors;