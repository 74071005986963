import {Redirect, Route} from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();

  return (
    currentUser === null ? <Redirect to="/login"/> : <Route
      {...rest}
      render={props => {
        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute;