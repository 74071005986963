import Footer from "../components/footer";
import {useEffect, useState} from "react";
import { useHistory } from "react-router";
import {db} from "../utils/firebaseUtils";
import { query } from "firebase/firestore";
import { BsFillCartCheckFill } from 'react-icons/bs';
import { RxCrossCircled } from 'react-icons/rx';





function Participants() {

  const [formations, setFormations] = useState([]);
  const formURL = window.location.pathname.substring(14)
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const getFormations = async () => {
      const formationRef = db.collection('formations');

      const snapshot = await formationRef.where('url','==', formURL).get();
      setFormations(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }

    getFormations();
  }, []);
  useEffect(() => {
    const getParticipants = async () => {
      let idValue = 0;
      if (formations[0] !== undefined) {
        idValue = formations[0].id;
      }
      const registrationRef = db.collection('formations/' + idValue + '/enrolled').orderBy("registrationExpiration", "desc");
      registrationRef.get().then((querySnapshot) => {
        const updatedParticipants = [];
        querySnapshot.forEach((doc) => {
          updatedParticipants.push({ id: doc.id, ...doc.data() });
        });
        setParticipants(updatedParticipants);
      });
    }
    formations !== [] && getParticipants()
  }, [formations]);

  return (
    <>
      <div className="container context gradient-container" id="backoffice-page">
        <div className="container justify-content-center g-0">
          <h2 className="mt-3 mb-5">Participantes</h2>
          {/* {formations[0] !== undefined && <h4>Número de participantes pagos: { formations[0].numberOfSpots}</h4>} */}
          <div className="row justify-content-center mb-5 table-responsive tablediv">
            <table className="table">
              <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">UP</th>
                <th scope="col">Email</th>
                <th scope="col">Nº tel.</th>
                <th scope="col">Pago</th>
                <th scope="col">Data Exp</th>
                <th scope="col">ID de transf</th>
              </tr>
              </thead>
              <tbody>
                {
                  participants !== [] && participants.map((part) => {
                    const date = part.registrationExpiration ? new Date(part.registrationExpiration) : null
                    return(
                      <tr key={part.id}>
                        <td>{part.fullName}</td>
                        <td>{part.studentNumber}</td>
                        <td>{part.email}</td>
                        <td>{part.phoneNumber}</td>
                        <td>{part.paid ? <BsFillCartCheckFill /> : <RxCrossCircled />}</td>
                        <td>{date ? (date.toISOString().substring(0,10) + ' ' + date.toISOString().substring(11, 19)) : 'ERRO'}</td>
                        <td>{part.easypayId}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Participants;