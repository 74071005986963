import { useHistory } from 'react-router-dom';
import {useAuth} from "../contexts/AuthContext";

function NavBar() {
  const history = useHistory();
  const { currentUser, logout } = useAuth();

  async function handleLogout() {
    try {
      await logout()
      history.push("/login")
    } catch {
      console.log("error")
    }
  }

  return (
    <>
    <nav className="navbar navbar-transparent navbar-top navbar-expand-md" role="navigation" id="navbar">
      <div className={currentUser ? "container" : "container container-auth"} >
        {
          currentUser ? <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
                                aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon">
            &#9776;
          </span>
          </button> : null
        }
        <div className="logo-container">
          <div className={currentUser ? "logo logo-auth" : "logo"}>
          <button className={!currentUser ? "logo-btn logo-btn-auth" : "logo-btn"} onClick={() => history.push('/')}>
              <img src="img/logo_white.png" alt="JuniFEUP logo" />
          </button>
          </div>
        </div>
        { currentUser ? <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <a className="nav-link" onClick={() => history.push('/backOffice')}>Formações <span className="sr-only">(current)</span></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={() => history.push('/organizadores')}>Organizadores</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={handleLogout}>Log Out</a>
            </li>
          </ul>
        </div> : null
        }
      </div>
    </nav>
      </>
  );
}

export default NavBar;
