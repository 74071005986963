import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import axios from 'axios';
import {doc, deleteDoc, updateDoc, collection, addDoc} from "firebase/firestore";
require('dotenv').config({ path: `${__dirname}/../../.env` })
const jwt = require('jsonwebtoken');

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// Initialize Firestore
export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();

export async function registerUser(user, formation) {
    const registrationRef = db.collection('formations/' + formation + '/enrolled');

    registrationRef.add(user)
    .then((res) => {
    })
    .catch ((error) => {
    console.error('Error submitting registration: ' + error);
    })

    if(user.newsletter){
        
        const payload = {
            subscriber: {
                name: user.fullName,
                email: user.email,
                phone_number: user.phoneNumber,
                origin: 'Site das formações'
            }
        };

        const token = jwt.sign(payload, process.env.REACT_APP_ERP_ACCESS_TOKEN, { expiresIn: '1h' });
        await axios
        .post(`${process.env.REACT_APP_ERP_HOST}/api/addSubscriber`, payload, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })
		.then((response) => {
			console.log("sent subscriber to erp");
		})
		.catch((error) => {
			console.log(error);
		});

    }
}

export async function getFilledSpace(formation){
    const registrationRef = db.collection('formations/' + formation + '/enrolled');
    // get formation data
    const formationRef = await (await db.collection('formations/').doc(formation).get()).data();
    const snapshot = await registrationRef.where('paid', '==', true).get();
    const participants = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    participants.forEach(async (participant) => {
        console.log(participant);
        console.log(formationRef);
        const payload = {
            name: participant.fullName,
            email: participant.email,
            phone_number: participant.phoneNumber,
            event_name: formationRef.name,
            event_date: formationRef.day,
        }

        const token = jwt.sign(payload, process.env.REACT_APP_ERP_ACCESS_TOKEN, { expiresIn: '1h' });
        await axios
        .post(`${process.env.REACT_APP_ERP_HOST}/api/event/addAttendee`, payload, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })
		.then((response) => {
			console.log("sent participant to erp");
		})
		.catch((error) => {
			console.log(error);
		});
    })

    return snapshot.size;
}

export async function addFormation(formation) {
    let id;
    await addDoc(collection(db, "formations"), formation).then((res) => {
        id = res.id;
    })

    const payload = {
        event: {
            name: formation.name,
            theme: formation.smallDescription.toString(),
            nr_vacancies: formation.numberOfSpots,
            price: formation.price,
            partner: formation.sponsors.toString(),
            description: formation.largeDescription.toString(),
            begin_date: formation.day,
        }
    };

    const token = jwt.sign(payload, process.env.REACT_APP_ERP_ACCESS_TOKEN, { expiresIn: '1h' });

    await axios
        .post(`${process.env.REACT_APP_ERP_HOST}/api/event/newExternalTraining`, payload, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })
		.then((response) => {
			console.log("sent event to erp");
		})
		.catch((error) => {
			console.log(error);
		});
    return id;
}

export function uploadFilesToStorage(formationId, file, type, isUpdate) {
    let path;
    if(type === "image"){
        path = 'formations-storage/' + formationId + '/image';
    }
    else {
        path = 'formations-storage/' + formationId + '/program'
    }
    let reference = storage.ref(path);
    let task = reference.put(file);

   task.then(async ()  => {
        console.log('File uploaded to the bucket!');
        if(!isUpdate) {
            await updateDoc(doc(db, 'formations/', formationId), {
                img: 'formations-storage/' + formationId + '/image',
                program: 'formations-storage/' + formationId + '/program'
            });
        }
    }).catch((e) => console.log('uploading file error => ', e));
}

export function uploadFilesSponsorToStorage(path, file) {
    let reference = storage.ref(path);
    let task = reference.put(file);

    task.then(() => {
        console.log('File uploaded to the bucket!');
    }).catch((e) => console.log('uploading file error => ', e));
}

export async function deleteFormation(id, url) {
    await deleteDoc(doc(db, 'formations/', id));
    await deleteStorage('formations-storage/' + url + '/image');
    await deleteStorage('formations-storage/' + url + '/program');
}

export async function updateFormation(formation) {
    await updateDoc(doc(db, 'formations/', formation.id), {
        name: formation.name,
        place: formation.place,
        day: formation.day,
        endInscDay: formation.endInscDay,
        active: formation.active,
        open: formation.open,
        endTime: formation.endTime,
        startTime: formation.startTime,
        price: formation.price,
        numberOfSpots: formation.numberOfSpots,
        url: formation.url,
        smallDescription: formation.smallDescription,
        largeDescription: formation.largeDescription,
        program: formation.program,
        img: formation.img,
        sponsors: formation.sponsors
    });
}

export async function urlExists(url) {
    const formationRef = db.collection('formations');
    let formations;
    const snapshot = await formationRef.get();
    formations = (snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    let number = 0;
    formations.map((formation) => {
        if(formation.url === url) {
            number++;
        }
        if(formation.url.split('_')[0] === url) {
            number++;
        }
    })
    return number === 0 ? number : number-1;
}

export function addSponsor(sponsor) {
    const sponsorsRef = db.collection('sponsors/');
    sponsorsRef.doc(sponsor.id).set({
        description: sponsor.description,
        img: sponsor.img
    }).then((res) => {
    })
      .catch ((error) => {
          console.error('Error submitting sponsor: ' + error);
      })
}

export async function deleteStorage(path) {
    let reference = storage.ref(path);
    let task = reference.delete();

    task.then(() => {
        console.log('File deleted from the bucket!');
    }).catch((e) => console.log('deleting file error => ', e));
}

export async function deleteSponsor(id) {
    await deleteDoc(doc(db, 'sponsors/', id));
    await deleteStorage('sponsors-storage/' + id + '/logo');
    const formationRef = db.collection('formations');
    let formations;
    const snapshot = await formationRef.get();
    formations = (snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    formations.map((formation) => {
        formation.sponsors.forEach(async (sponsor, index) => {
            if(sponsor === id) {
                formation.sponsors.splice(index, 1);
                await updateDoc(doc(db, 'formations/', formation.id), {
                    sponsors: formation.sponsors
                });
            }
        })
    })

}

export async function updateSponsor(sponsor) {
    await updateDoc(doc(db, 'sponsors/', sponsor.id), {
        description: sponsor.description,
        img: sponsor.img
    });
}

export async function sponsorAlreadyExists(name) {
    const sponsorsRef = db.collection('sponsors/');
    let sponsors;
    let found = false;
    const snapshot = await sponsorsRef.get();
    sponsors = (snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})));
    sponsors.map((sponsor) => {
        if (sponsor.id === name) {
            found =  true;
        }
    })
    return found;
}