import Footer from "../components/footer";
import {useHistory} from "react-router-dom";
import {useState} from "react";
import {
  addSponsor,
  sponsorAlreadyExists,
  uploadFilesSponsorToStorage
} from "../utils/firebaseUtils";
import OfficeModal from "../components/officeModal";

function AddSponsor() {
  const history = useHistory();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [image, setImage] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    const forms = document.getElementsByClassName('needs-validation');
    Array.prototype.filter.call(forms, async function(form) {
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        }
        else if (await sponsorAlreadyExists(name)){
          document.getElementById('name').style.borderColor = "#dc3545";
          document.getElementById('name-feedback').style.display = "inline";
          e.preventDefault();
          e.stopPropagation();
        }
        else if(!document.getElementById('smallDesc').value.match('^(?!.*<[^>]+>).*')) {
          document.getElementById('smallDesc').style.borderColor = "#dc3545";
          document.getElementById('smallDesc-feedback').style.display = "inline";
          e.preventDefault();
          e.stopPropagation();
        }
        else if (image.type.split('/')[0] !== "image"){
          document.getElementById('image').style.borderColor = "#dc3545";
          document.getElementById('image-feedback').style.display = "inline";
          e.preventDefault();
          e.stopPropagation();
        }
        else {
          setModalInfo({
            title: 'Adicionar Organizador',
            text: 'Tens a certeza que queres adicionar o organizador?',
            actionFunc: add,
          })
          setModalOpen(true);
        }
        form.classList.add('was-validated');
      }, false);
  }

  const add = async () => {
    const sponsor = {
      id: name,
      description: description,
    };
    sponsor.img = 'sponsors-storage/' + name + '/logo';
    addSponsor(sponsor);
    uploadFilesSponsorToStorage(sponsor.img, image)
    history.push('/organizadores')
  };

  return (
    <div>
      <div className="container context gradient-container" id="addSponsor-page">
        <div className="row align-items-center justify-content-center">
          <div className="col-8 formacao-intro">
            <div className="row align-items-center justify-content-center mt-5">
              <form className="needs-validation" noValidate={true} id="addSponsorForm" onSubmit={(e) => handleSubmit(e)}>
                <div className="form-group optionCheck">
                  <div className="row d-flex">
                    <h2>Adicionar Organizador</h2>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row d-flex justify-content-center">
                    <div className="col label-input">
                      <label htmlFor="name">Nome</label>
                      <input type="text"
                             className="form-control" id="name" placeholder="Nome do organizador"
                             pattern="^[a-zA-Z\s]*$"
                             onChange={e => setName(e.target.value)}
                             required/>
                      <div className="invalid-feedback" id="name-feedback">
                        Nome é obrigatório, não pode ser igual a um já existente, e só pode conter letras e/ou espaços!
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row d-flex justify-content-center">
                    <div className="col label-input">
                      <label htmlFor="smallDesc">Descrição</label>
                      <textarea className="form-control" id="smallDesc" rows="3" required
                                placeholder="Pequena Descrição"
                                onChange={e => setDescription(e.target.value)}/>
                      <div className="invalid-feedback" id="smallDesc-feedback">
                        Descrição é obrigatória e não pode conter tags de HTML!
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row d-flex justify-content-center">
                    <div className="col label-input">
                      <label htmlFor="image">Imagem</label>
                      <input type="file"
                             className="form-control" id="image" onChange={e => setImage(e.target.files[0])}
                             required />
                      <div className="invalid-feedback" id="image-feedback">
                        Imagem é obrigatória!
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row d-flex justify-content-center actions-div">
                    <div className="buttons-div">
                      <button type="submit" className="btn modal-btn" >
                        <strong>Adicionar</strong>
                      </button>
                      {modalOpen ? <OfficeModal setModalOpen={setModalOpen}
                                                title={modalInfo.title}
                                                text={modalInfo.text}
                                                actionFunc={modalInfo.actionFunc}
                      /> : null}
                    </div>
                    <div className="buttons-div">
                      <button type="button" className="btn modal-btn" onClick={() => history.push('/organizadores')}>
                        <strong>Cancelar</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )

}

export default AddSponsor;