const axios = require('axios');

const apiUrl = process.env.REACT_APP_BACKEND_URL + "/easypay/createPayment";

export async function payment(user, formationName, formationValue, callback) {
	const payload = user;
	axios.post(apiUrl, {payload, formationName, formationValue} )
		.then(async (response) => {
			if (response.status === 201) {
				callback(null,response.data);
			}
		})
		.catch((error) => {
			console.log(error.message);
			console.log('Erro ao chamar pagamento por easypay');
			callback(error);
		});
}
