import {simplifiedDate} from "../utils/formationUtils";
import * as firebase from "../utils/firebaseUtils";
import {useEffect, useState} from "react";

function Card(props) {
  const [image, setImage] = useState([]);

  useEffect(() => {
    const getImage = async () => {
        firebase.storage.ref(props.img).getDownloadURL().then(url => {
          setImage(url);
        })
    };

    getImage();
  }, []);

  return (
    <a href={'/formacao' + props.link}>
      <div className="card">
        <img src={image} className="card-img-top" alt="card-img"/>
        <div className="card-body">
          <h8 className="card-title"><strong className="card-title-real">{props.name} </strong><span className={'new-tag ' + 'new-tag-' + props.color}>novo</span></h8>
          <ul>
            <li><i className={"bi bi-calendar-check-fill " + 'icon-' + props.color}/>{simplifiedDate(props.date)}</li>
            <li><i className={"bi bi-clock-fill " + 'icon-' + props.color}/>{props.startTime}-{props.endTime}</li>
            <li><i className={"bi bi-currency-euro " + 'icon-' + props.color}/>{props.price}</li>
          </ul>
        </div>
      </div>
  </a>
  );
}

export default Card;