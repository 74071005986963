import Modal from '../components/modal'
import ConfirmationModal from '../components/confirmationModal';
import RejectionModal from '../components/rejectionModal';
import ErrorModal from '../components/errorModal';
import {useEffect, useState} from 'react';
import * as firebase from "../utils/firebaseUtils";
import {simplifiedDate} from "../utils/formationUtils";

function CardButton(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [rejectionMessage, setRejectionMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const [full, setFull] = useState(false);

  useEffect(() => {
    const handleSpace = async () => {
      if (await firebase.getFilledSpace(props.id) === props.maxSpots) {
        setFull(true);
      }
    }

    handleSpace()
  }, []);

  return (
    <div>
      <div className="card row justify-content-center">
        <img src={props.img} className="card-img-top" alt="card-img"/>
        <div className="card-body">
          <h4 className="card-title"><strong className="card-title-real">{props.title} </strong><span
            className={'new-tag ' + 'new-tag-' + props.color}>novo</span></h4>
          <ul className='cardButton-ul'>
            <li><i className={"bi bi-calendar-check-fill " + 'icon-' + props.color}/>{simplifiedDate(props.date)}</li>
            <li><i className={"bi bi-clock-fill " + 'icon-' + props.color}/>{props.startTime}-{props.endTime}</li>
            <li><i className={"bi bi-currency-euro " + 'icon-' + props.color}/>{props.price}</li>
          </ul>
        </div>
      </div>
      {
        props.open && !full ? <div className="row g-0 justify-content-center pb-3">
          <button onClick={() => {
            setModalOpen(true)
          }} className={"insc-button insc-button-" + props.color}><strong>Inscrição na formação</strong></button>
        </div> : <></>
      }
      {modalOpen ? <Modal setModalOpen={setModalOpen} setConfirmationMessage={setConfirmationMessage}
                          setRejectionMessage={setRejectionMessage} setErrorMessage={setErrorMessage}
                          setPaymentData={setPaymentData} formation={props.id} formationName={props.name}
                          startTime={props.startTime} endTime={props.endTime} date={props.date} price={props.price}
                          maxSpots={props.maxSpots}
      /> : null}
      {confirmationMessage ?
        <ConfirmationModal suggestion="Agile & Scrum" setConfirmationMessage={setConfirmationMessage}
                           paymentData={paymentData}/> : null}
      {rejectionMessage ? <RejectionModal suggestion="Agile & Scrum" setRejectionMessage={setRejectionMessage}/> : null}
      {errorMessage ? <ErrorModal suggestion="Agile & Scrum" setErrorMessage={setErrorMessage}/> : null}
    </div>

  );
}

export default CardButton;
